var validateEmail = function(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

var search_terms, search_rgx, valid_search;

var isValidBarcode = function (barcode) {
  // check length
  if (barcode.length < 8 || barcode.length > 18 ||
      (barcode.length != 8 && barcode.length != 12 &&
      barcode.length != 13 && barcode.length != 14 &&
      barcode.length != 18)) {
    return false;
  }

  var lastDigit = Number(barcode.substring(barcode.length - 1));
  var checkSum = 0;
  if (isNaN(lastDigit)) { return false; } // not a valid upc/ean

  var arr = barcode.substring(0,barcode.length - 1).split("").reverse();
  var oddTotal = 0, evenTotal = 0;

  for (var i=0; i<arr.length; i++) {
    if (isNaN(arr[i])) { return false; } // can't be a valid upc/ean we're checking for

    if (i % 2 === 0) { oddTotal += Number(arr[i]) * 3; }
    else { evenTotal += Number(arr[i]); }
  }
  checkSum = (10 - ((evenTotal + oddTotal) % 10)) % 10;

  // true if they are equal
  return checkSum == lastDigit;
};

var validateFormParts = function(el){
	var error = false;
	
	var inputs = el.find('input[required], select[required]');
	var options = el.find('input.validate');
	
	inputs.off('focus').on('focus', function(){
		$(this).removeClass('error-warning');
	});
	
	options.off('focus').on('focus', function(){
		$(this).removeClass('error-warning');
	});
	
	inputs.each(function(){
		
		var value = $(this).val();
		var name = $(this).attr('name');
		
		switch(true){
			case name.toLowerCase() == 'postal':
				if(!/^[a-z]?[\s\-]?[0-9]+$/i.test(value)) {
					error = true;
					$(this).addClass('error-warning');
				}
				break;
			case name.toLowerCase() == 'bic':
				
				value = value.replace(/\s+/g, '');
				$(this).val(value);
				
				if( !/^([a-z]){4}([a-z]){2}([0-9a-z]){2}([0-9a-z]{3})?$/i.test(value)) {
					error = true;
					$(this).addClass('error-warning');
				}
				
				break;
			case name.toLowerCase() == 'iban':
				if(!IBAN.isValid(value)){
					error = true;
					$(this).addClass('error-warning');
				}
				break;
			case name.toLowerCase() == 'email':
				if(!validateEmail(value)){
					error = true;
					$(this).addClass('error-warning');
				}
				break;
			case name.toLowerCase().indexOf('ean') > -1:
				if(!isValidBarcode(value)){
					error = true;
					$(this).addClass('error-warning');
				}
				break;
			case name.toLowerCase() == 'purchase_year':
				
				var year = el.find('select#purchase_year').val();
				var month = el.find('select#purchase_month').val();
				var day = el.find('select#purchase_day').val();
				
				if( year.length === 0 || year.length === 0 || year.length === 0 ) {
					
					error = true;
					$(this).addClass('error-warning');
					
				} else {
				
					var purchaseDate = new Date(
						parseInt(el.find('select#purchase_year').val()), 
						parseInt(el.find('select#purchase_month').val()) -1, 
						parseInt(el.find('select#purchase_day').val()) ) ;
						
					var tmp;
					
					tmp = start_purchase.split("-");
					var startDate = new Date(parseInt(tmp[0]), parseInt(tmp[1]) -1, parseInt(tmp[2]), 0, 0, 0);
					
					tmp = end_purchase.split("-");
					var endDate = new Date(parseInt(tmp[0]), parseInt(tmp[1]) -1, parseInt(tmp[2]), 0, 0, 0);
														
					if( purchaseDate.getTime() < startDate.getTime() || purchaseDate.getTime() > endDate.getTime() ) {

						error = true;
						
						if(null === dateMessage) dateMessage = $('.purchase_warning').text();						
						$('.purchase_warning').html(dateMessage.replace('[start_date]', moment(start_purchase).format('L') ).replace('[end_date]', moment(end_purchase).format('L') ));
						
						$('.purchase_warning').show();
												
						el.find('select#purchase_year').addClass('error-warning');
						el.find('select#purchase_month').addClass('error-warning');
						el.find('select#purchase_day').addClass('error-warning');
					
					} else {
						$('.purchase_warning').hide();
					}
				}
				
				break;
			default:
				if( value.length <= 0 ) {
					error = true;
					$(this).addClass('error-warning');
				}
		}
				
	});
	
	options.each(function(){
		
		var value = $(this).val();
		var name = $(this).attr('name');
		
		if(value.length > 0) {
		
			switch(true){
				case name.toLowerCase() == 'bic':
					
					value = value.replace(/\s+/g, '');
					$(this).val(value);
					
					if( !/^([a-z]){4}([a-z]){2}([0-9a-z]){2}([0-9a-z]{3})?$/i.test(value)) {
						error = true;
						$(this).addClass('error-warning');
					}
					
					break;
				case name.toLowerCase() == 'iban':
					if(!IBAN.isValid(value)){
						error = true;
						$(this).addClass('error-warning');
					}
					break;
				case name.toLowerCase().indexOf('ean') > -1:
					if(!isValidBarcode(value)){
						error = true;
						$(this).addClass('error-warning');
					}
					break;
			}		
		}
	});
	
	return !error;
};

var submitNow = false;
var dateMessage = null;

var showCookieBar = function(force){
	if(undefined === force) force = false;
	$.cookieBar({
		message: cookieMessages[0],
		acceptText: cookieMessages[1],
        fixed: true,
        bottom: true,
        acceptButton: true,
        effect: 'slide',
        forceShow: force
    });
};

$(document).ready(function(){
	
	
	/*showCookieBar();
	$('.cookie').click(function(){
		showCookieBar(true);
	});*/
	
	moment.locale( $('html').attr('lang') );
	
	$('.lang').on('touchend', function (e) {
		$(this).addClass('active');
	});
	
	$(".top").click(function(){
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	});
	
	$('.paging-text').html( '1/' + $('.form-parts').length );
	$('.btn.next').click(function(evt){
		
		var allparts	= $('.form-parts');
		var currentId	= allparts.index($('.form-parts.active'));
		
		var current = $(allparts.get(currentId));
		var next 	= $(allparts.get(currentId+1));
		
		if(validateFormParts(current)) {
		
			current.removeClass('active');
			next.addClass('active');
			
			$('.paging-text').html( currentId+2 + '/' + allparts.length );
			
			if(currentId >= allparts.length-2) {
				
				$('.btn.next').addClass('inactive');
				$('.btn.send').removeClass('inactive');
			}
			
			$('.btn.previous').removeClass('inactive');
		
		}
		
		evt.preventDefault();
		return false;
		
	});
	
	$('select.detect_change').change(function(){
		
		var selectEl = $(this).get(0);
		var index = selectEl.selectedIndex;
		var parent = $(this).parents('.row');
		
		parent.find('select.detect_change').each(function(){
			$(this).get(0).selectedIndex = index;
		});
		
		var hdid = $('input[name=ID_HD]').val();
		
		$.get('/reference/validity/' + hdid + '/' + $(this).parents('.row').find('label[for=reference] select').val(), function(datas){
			start_purchase = datas.start_purchase;
			end_purchase = datas.end_purchase;
		});
	});
	
	
	$('.btn.previous').click(function(evt){
		
		var allparts	= $('.form-parts');
		var currentId	= allparts.index($('.form-parts.active'));
		
		var current  = $(allparts.get(currentId));
		var previous = $(allparts.get(currentId-1));
				
		current.removeClass('active');
		previous.addClass('active');
		
		$('.paging-text').html( currentId + '/' + allparts.length );
		
		if(currentId <= 1) {
			$('.btn.previous').addClass('inactive');
		}
		
		$('.btn.send').addClass('inactive');
		$('.btn.next').removeClass('inactive');
				
		evt.preventDefault();
		return false;
	});
	
	$('.btn.send').click(function(evt){
		
		if(validateFormParts($('.form-parts.active'))) {
			$('.loading').fadeIn('fast');
			submitNow = true;
			$('#purchase_form').get(0).submit();
		}
						
		evt.preventDefault();
		return false;
	});
	
	$('.thanks, .error button').click(function(){
		$('.thanks, .error').fadeOut( function(){
			$(this).remove();
		});
	});
	
	$('.thanks button, .error button').click(function(){
		for( var i in lead){
			switch(i){
				case 'ticket':
				case 'ean':
				case 'reference':
				case 'purchase':
				case 'shop':
					break;
				case 'birthdate':
					var dates = lead[i].split('-');
					
					$('select[name="birth_year"]').val( parseInt(dates[0]) );
					$('select[name="birth_month"]').val( parseInt(dates[1]) );
					$('select[name="birth_day"]').val( parseInt(dates[2]) );
					
					break;
				default:
					$('input[name="' + i + '"], select[name="' + i + '"]').val(lead[i]);				
			}
		}
		
		$('.form-parts').removeClass('active');
		$($('.form-parts').get( $('.form-parts').length -2 )).addClass('active');
		$('.btn.next').trigger('click');
		
	});
	
	$('.mobile.question_mark').click(function(){
		
		if( ! $('.questions').hasClass('active') ) {
			$(this).html('&times;');
		} else {
			$(this).html('?');
		}
		
		$('.questions').toggleClass('active');
	});

	$('.search_input').parent('form').on('submit', function () {
		if ($(window).width() < 540) {
			$(window).scrollTop($('ul.blocks').offset().top - 64);
		}
		document.querySelector('.search_input').blur();
		$('.search_input_validation').hide();
		return false;
	});

	$('.search_input').blur(function () {
		$('.search_input_validation').hide();
	});

	$('.search_input').focus(function () {
		$('.search_input_validation').show();
	});

	$('.search_input').on('keyup', function () {
		search_terms = $(this).val().split(' ');
		$('.unfloater').removeClass('unfloater');
		$('.details .description').each(function () {
			valid_search = true;

			for (var i = 0; i < search_terms.length; i++) {
				search_rgx = new RegExp(search_terms[i], 'gi');
				if (!search_rgx.test($(this).text())) {
					valid_search = false;
				}
			}

			if ( valid_search ) {
				$(this).parents('li.block').removeClass('invalid_search');
			} else {
				$(this).parents('li.block').addClass('invalid_search');
			}
		});
		$('li.block').not('.invalid_search').last().addClass('unfloater');

		if ($('li.block').not('.invalid_search').length == 0 && $('ul.blocks p.no_result_search').length < 1 ) {
			$('ul.blocks').prepend('<p class="no_result_search">' + $('ul.blocks').data('noresult') + '<a class="search_clearer" href="#">×</a></p>');
		} else if ($('li.block').not('.invalid_search').length > 0) {
			$('ul.blocks > p').remove();
		}
	});

	$('ul.blocks').on('click', 'a.search_clearer', function () {
		$('.search_input').val('').trigger('keyup');
		return false;
	});

	$('.infos-trigger').on('click', function(){
		$(this).parents('.block').find('.infos').slideDown();
	});
	
	$('.block.message > a').click(function(){
		$(this).parents('.block').find('.infos').slideDown();
		return false;
	});

	$('.close').on('click', function(){
		$('.infos').slideUp(300);
	});

});
