$('.btn-choice ').click(function() {
  	//$(this).add($(this).siblings()).toggleClass("active");
  	$(this).parents('.category').toggleClass('active');
});


$('.cookie_infos').click(function() {
  	$(".modal").addClass("show");
});

$('.close').click(function() {
  	$(".modal").removeClass("show");
});


$('.open-cookie').click(function() {
  	$(".cookie-bar").removeClass("noshow");
  	return false;
});

var gdprGetParams = {};
$('.accept_cookie').click(function() {
  	$(".cookie-bar").addClass("noshow");
  	
	setCookie('_gdpr-settings', JSON.stringify(gdprGetParams) );

  	$.get( "/cookies/gdpr" , gdprGetParams, function(scripts){
  		$("body").append(scripts);
  	} ,"html"  );
  	
  	return false;
});

var setCookie = function(name, value) {
    var d = new Date();
    d.setTime(d.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
    var cookieString = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    if(location.protocol.indexOf('https') > -1) cookieString += ';secure';
    document.cookie = cookieString;
};

$('.btn-save').click(function(){
		
		var categories = $('.category.active');
		categories.each(function(){
			var track = $(this).data('tracking');
			gdprGetParams[track] = 1;
		});
		
		$(".modal").removeClass("show");

		return false;
}); 